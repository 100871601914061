<template>
    <div
        class="date-block"
        :class="[{ 'is-multi-day': isMultiDay }, color, size]"
    >
        <div class="date-block__month">
            {{ start.month }}
        </div>
        <div class="date-block__day">
            {{ start.day }}
        </div>
        <template v-if="isMultiDay">
            <div class="date-block__separator">
                -
            </div>
            <div class="date-block__month">
                {{ end.month }}
            </div>
            <div class="date-block__day">
                {{ end.day }}
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DateBlock extends Vue {

    @Prop() startDate!: Date;
    @Prop() endDate!: Date;
    @Prop() size!: undefined | 'is-small';
    @Prop() color!: string;

    get isMultiDay(): boolean {
        // DD-FE-2729 -On the shop page events lasting less than 24 hours but spanning multiple days
        // will be displayed with only the starting date shown (on the DateBlock component),
        // even if the event technically extends over 2 days.

        // difference from event start and end in hours
        return (
            Math.abs(this.endDate.valueOf() - this.startDate.valueOf()) / 36e5
            > 24
        );
    }

    get start(): { day: string; month: string } {
        return {
            day: this.day(this.startDate),
            month: this.month(this.startDate),
        };
    }

    get end(): { day: string; month: string } {
        return {
            day: this.day(this.endDate),
            month: this.month(this.endDate),
        };
    }

    day(date: Date): string {
        return date.getDate().toString();
    }

    month(date: Date): string {
        return this.$localization.locale.monthNamesAbbr[date.getMonth()];
    }

}
</script>

<style lang="scss" scoped>
.date-block {
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--ot-shop-color-text);
    color: var(--ot-shop-color-text-dark);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__month {
        font-size: 0.625rem;
        line-height: 0.625rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    &__day {
        font-size: 1.125rem;
        line-height: 1.25rem;
        font-weight: 600;
    }

    &.is-small {
        height: 1.5rem;
        width: 1.5rem;
    }

    &.is-multi-day {
        height: 6rem;
    }

    &.is-small.is-multi-day {
        height: 4.5rem;
    }

    &.is-small & {
        &__month {
            font-size: 0.375rem;
            line-height: 0.375rem;
        }

        &__day {
            font-size: 0.75rem;
            line-height: 0.75rem;
        }
    }

    &.is-brand {
        background-color: var(--ot-shop-color-brand);
        color: var(--ot-shop-color-brand-invert);
    }
}
</style>
