<template>
    <div
        v-if="event.tickets.length"
        class="ot-card has-no-padding event-card"
    >
        <div class="event-card__header">
            <CardHeader
                icon="oti-ticket"
                heading="h2"
                :title="event.name"
            >
                <template #label>
                    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                    <label
                        v-if="showEventDate"
                        class="ot-text-small"
                    >{{
                        event.start ?
                            $l.dateTime(
                                event.start,
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                            ) : ''
                    }}
                        -
                        {{
                            event.end ?
                                $l.dateTime(
                                    event.end,
                                    Intl.DateTimeFormat().resolvedOptions().timeZone
                                ) : ''
                        }}
                    </label>
                    <span
                        v-if="showLocation && event.location"
                        class="event-card__header__location ot-text-tiny"
                    ><span
                         v-if="event.location.name"
                         class="event-card__header__location__name"
                     >{{ event.location.name }}</span>
                        <span
                            v-if="event.location.name && event.location.address"
                        >
                            -
                        </span>
                        <span
                            v-if="event.location.address"
                            class="event-card__header__location__address"
                        >
                            {{ event.location.address }}
                        </span>
                    </span>
                </template>
            </CardHeader>
        </div>

        <div class="event-card__content">
            <!-- <button class="ot-button is-outline event-card__add-to-calendar">
                <DateBlock
                    :date="new Date()"
                    size="is-small"
                    color="is-brand"
                />
                Add to my calendar
            </button> -->

            <OrderTicket
                v-for="(ticket, i) in tickets.available"
                :id="ticket.guid"
                :key="ticket.guid"
                class="event-card__ticket"
                :ticket="ticket"
                :index="i + 1"
                :count="tickets.available.length"
                :opened="ticket.guid === openedCard"
                :locked="isLocked"
                :show-qr-code="showQrCodes"
                :hide-download-action="hideDownloadActions"
                @toggle="$emit('toggle', ticket.guid)"
                @next="$emit('next')"
            />

            <div
                v-if="tickets.unavailable.length && tickets.available.length"
                class="
                    ot-separator
                    has-label
                    event-card__unavailable__separator
                "
            >
                {{ $t('order.components.event_card.unavailable_tickets') }}
            </div>
            <OrderTicket
                v-for="ticket in tickets.unavailable"
                :key="ticket.guid"
                class="event-card__unavailable__ticket"
                :opened="ticket.guid === openedCard"
                :ticket="ticket"
                @toggle="$emit('toggle', ticket.guid)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { IOrderEvent, IOrderTicket } from '@openticket/lib-order';
import DateBlock from '../../../components/DateBlock.vue';
import CardHeader from './CardHeader.vue';
import OrderTicket from './OrderTicket/OrderTicket.vue';
import CardSection from './CardSection.vue';

@Component({
    components: {
        DateBlock,
        OrderTicket,
        CardHeader,
        CardSection,
    },
})
export default class EventCard extends Vue {

    @Prop() event!: IOrderEvent;

    @Prop({ default: false, type: Boolean })
        showQrCodes!: boolean;

    @Prop({ default: false, type: Boolean })
        hideDownloadActions!: boolean;

    @Prop({ default: null })
        openedCard!: string | null;

    get tickets(): {
        available: IOrderTicket[];
        unavailable: IOrderTicket[];
        } {
        const available: IOrderTicket[] = [];
        const unavailable: IOrderTicket[] = [];

        for (const ticket of this.event.tickets) {
            if (!ticket.invalidated_since) {
                available.push(ticket);
            } else {
                unavailable.push(ticket);
            }
        }

        return {
            available,
            unavailable,
        };
    }

    get isLocked(): boolean {
        return this.$order.data.status !== 'paid';
    }

    get showLocation(): boolean {
        if (!this.$settings) {
            return true;
        }
        return !!this.$settings.static.order.components.eventCard.showLocation;
    }

    get showEventDate(): boolean {
        if (!this.$settings) {
            return true;
        }
        return (
            !!this.event.start
            && !!this.event.end
            && !!this.$settings.static.order.components.eventCard.showEventDate
        );
    }

}
</script>

<style lang="scss" scoped>
.event-card {
    margin-bottom: 1rem;
    text-align: center;

    &__info {
        margin-bottom: 1rem;
    }

    &__ticket {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        label {
            opacity: 0.5;
        }

        &__location {
            display: block;
            opacity: 0.5;
        }
    }

    &__content {
        padding: 0 1.25rem;
        padding-bottom: 1.25rem;
    }

    &__unavailable {
        &__separator {
            margin: 1.5rem 0;
        }

        &__ticket {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__add-to-calendar {
        margin-bottom: 2rem;

        .date-block {
            margin-right: 1rem;
        }
    }
}
</style>
